import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { User } from "../models/user.model";
import * as moment from "moment";
import { Observable, throwError, BehaviorSubject } from "rxjs";

import { tap, map, retry, catchError } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Role } from "../models/role.model";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  getUser(id: any, page: any) {
    throw new Error("Method not implemented.");
  }

  baseUrl = environment.apiserver;
  userSession: User;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private httpClient: HttpClient) {
    //getting current user
    this.user.subscribe((data) => {
      this.userSession = data;
    });
  }

  private session = new BehaviorSubject(new User());
  user: Observable<User> = this.session.asObservable();

  private waitingSession = new BehaviorSubject(false);
  waiting: Observable<boolean> = this.waitingSession.asObservable();

  updatedDataSelection(data: User) {
    this.session.next(data);
  }

  updatedwaitingSession(data: boolean) {
    this.waitingSession.next(data);
  }

  logout(): Observable<string> {
    let url = `${this.baseUrl}/logout`;

    return this.httpClient
      .get<string>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMemberByUser(id?: number, page?: number): Observable<User> {
    let url = `${this.baseUrl}/user?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/user/` + id;
    }

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCompanyAdminUsers(id): Observable<User> {
    let url = `${this.baseUrl}/companyAdminUsers/` + id;

    // if (id > 0) {
    //   url = `${this.baseUrl}/user/` + id;
    // }

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCompanyAdminRole(id): Observable<Role> {
    let url = `${this.baseUrl}/adminrole/company/` + id;

    return this.httpClient
      .get<Role>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getEmployeeUsers(id: number): Observable<User> {
    let url = `${this.baseUrl}/employeeuser`;

    if (id > 0) {
      url = `${this.baseUrl}/employeeuser/` + id;
    }

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAdminUser(): Observable<User> {
    let url = `${this.baseUrl}/adminusers`;

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateRead(id: number): Observable<any> {
    let url = `${this.baseUrl}/updateread/` + id;

    return this.httpClient
      .get<any>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getNotification(id: number, page?: number) {
    let url = `${this.baseUrl}/notifications/` + id + `?page=1`;

    // if (page > 0) { debugger;
    //   url = `${this.baseUrl}/notifications/` + id;
    // }
    if (page > 0) {
      url = `${this.baseUrl}/notifications/` + id + `?page=` + page;
    }

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));
  }

  getUnreadNotifications(id?: number, page?: number) {
    let url = `${this.baseUrl}/unreadnotifications?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/unreadnotifications/` + id;
    } else if (page > 0) {
      url = `${this.baseUrl}/unreadnotifications?page=` + page;
    }

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));
  }

  updateReadOrUnread(data?: any) {
    const {event, id} = data;
  
    console.log(data); 
    let url = `${this.baseUrl}/updatereadunread?page=1`;
    
      // url = `${this.baseUrl}/updatereadunread/` + id;
    
      url = `${this.baseUrl}/updatereadunread/${event}/${id}` ;
    

    return this.httpClient.get<any[]>(url).pipe(retry(1), catchError(this.handleError));
  }


  deleteUser(id?: number): Observable<User> {
    console.log(id);
    let url = `${this.baseUrl}/deleterecodes`;

    if (id > 0) {
      url = `${this.baseUrl}/deleterecodes/` + id;
    }

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  validateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/login`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendPasswordResetLink(data: any) {
    let url = `${this.baseUrl}/resetpwemail`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  public isLoggedIn() {
    var yes = moment().isBefore(this.getExpiration());
    return yes;
  }

  resetPw(data: any): Observable<any> {
    let url = `${this.baseUrl}/resetpw`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  resetPassword(data: any): Observable<any> {
    let url = `${this.baseUrl}/resetpassword`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getExpiration() {
    // this.user.subscribe(data =>{
    //   const expiration = data.tokenexpire;
    //   //const expiresAt = JSON.parse(expiration);
    //   return (expiration);
    // });

    const expiration = this.userSession.tokenexpire;
    return expiration;
  }

  getLoggedUser(): Observable<boolean> {
    let url = `${this.baseUrl}/loggeduser`;
    var data = {};

    this.user.subscribe((res) => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  endSession(): Observable<boolean> {
    let url = `${this.baseUrl}/logout`;

    var data = {};

    this.user.subscribe((res) => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveUser(data: any): Observable<User> {
    console.log(data);
    let url = `${this.baseUrl}/register`; debugger
    if (data["id"] > 0) {
      url = `${this.baseUrl}/update/` + data["id"];
    }

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveEmpUser(data: any): Observable<User> {
    console.log(data);
    let url = `${this.baseUrl}/registerempuser`;
    if (data["id"] > 0) {
      url = `${this.baseUrl}/update/` + data["id"];
    }

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  createCompanyBackup(id: number): Observable<any> {
    let url = `${this.baseUrl}/allRecords/store/`+id; debugger;

    return this.httpClient
      .post<any>(url, JSON.stringify(id), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    debugger;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      debugger;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      debugger;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
